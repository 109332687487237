export function getPopularProblems() {
  const { $api } = useNuxtApp();
  return $api<Array<SeoTitles>>('/grouped-popular-repair-services/', {
    method: 'GET',
  });
}

export function getRepairServiceArticle(params: SeoRepairServiceArticleParams) {
  const { $api } = useNuxtApp();
  return $api<SeoArticle>('/seo/repair-service-article/', {
    method: 'GET',
    params: params,
  });
}

export function getDeviceProblemArticle(params: SeoDeviceProblemArticleParams) {
  const { $api } = useNuxtApp();
  return $api<SeoArticle>('/seo/device-problem-article/', {
    method: 'GET',
    params: params,
  });
}

export function getRepairServiceDescription(params: SeoRepairServiceDescriptionParams) {
  const { $api } = useNuxtApp();
  return $api<SeoRepairServiceDescription>('/seo/repair-service-description/', {
    method: 'GET',
    params: params,
  });
}
